<template>
  <div>
    <div class="product_desc_1">
      <h3>赋码系统是什么</h3>
      <p>
        给产品赋上唯一的身份码，从最小单元的身份数据开始，在各级包装码之间建立关联关系，给后续的物流追踪、互动营销提供基础数据的系统。
      </p>
      <h5>生产全程可视化 让生产信息一目了然</h5>

      <div class="desc_img"></div>
      <p>根据生产线情况配备相应的赋码设备，结合产品为其选择合适的赋码形式</p>
      <p>
        根据产品包装级数，从基础包装到最大包装形式均可以在线形成产品防伪物流码的关联管理。
      </p>
    </div>
    <div class="product_desc_1 product_desc_2">
      <h3>赋码系统的优势</h3>
      <p>
        生产线赋码设计建立于不改变生产线原生产工艺流程的基础上，采用工业自动化、智能识别、多重信息加密技术及软硬件集成的方式。
      </p>
      <h5>以唯一身份码为入口，加速工业4.0进程</h5>
      <div class="desc_img desc_img_2"></div>
      <p>
        灵活解决企业多生产线、多包装工位和多包装规范等需求；具有商品赋码、数据采集、建立多级包装关联关系的功能。
        <br />支持自动关联模式、扫描关联模式及多级关联模式并行；一目了然的作业提示，机动的应变能力，灵活处理生产异常。
        <br />配合相关物联网硬件以及识别和控制技术，实现数据实时管理及系统整合。
      </p>
    </div>
    <div class="product_desc_1 product_desc_3">
      <h3>赋码系统的功能</h3>
      <p>
        生产线赋码系统面向生产制造型企业，采用工业自动化、智能识别、多重信息加密及软硬件系统集成等技术，为每一件商品建立“一物一码”或“一物多码”，具有产品赋码、数据采集、建立包装关联关系等功能。
      </p>
      <div class="desc_img desc_img_3"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      autotagging_title: {},
    };
  },
  methods: {
    load_data() {
      const { product_center } = require("@/assets/data/data.json");
      this.autotagging_title = product_center.autotagging_title;
    },
  },
  created() {
    this.load_data();
  },
};
</script>

<style lang="less" scoped>
.product_desc_1 {
  padding: 50px 30px;
  h3 {
    font-size: 50px;
    font-weight: 700;
  }
  p {
    text-align: justify;
    font-size: 35px;
    color: #333;
    margin: 20px 0;
  }
  .desc_img {
    width: 100%;
    height: 400px;
    // border: 1px solid #000;
    background: url("~@/assets/img/product/autotagging_1.jpg") no-repeat
      center/cover;
    border-radius: 10px;
    margin: 10px 0;
  }
  h5 {
    font-size: 35px;
    text-align: center;
    font-weight: 500;
    margin: 20px 0;
  }
}
.product_desc_2 {
  background-color: #f7f7f7;
  .desc_img_2 {
    background: url("~@/assets/img/product/autotagging_2.jpg") no-repeat
      center/cover;
  }
}
.product_desc_3 {
  .desc_img_3 {
    background: url("~@/assets/img/product/autotagging_3.jpg") no-repeat
      center/contain;
  }
}
</style>